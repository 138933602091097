// numberFormatter.js

import toast, { Toaster } from 'react-hot-toast'

async function formatNumberArray(input) {

    let numbers = Array.isArray(input) ? input : input.split(',');
    const uniqueNumbers = [...new Set(numbers.map(formatNumber))];
    let phones = uniqueNumbers.filter(num => num !== null);
    phones = uniqueNumbers.filter(num => num !== undefined);
    // const validateNumbers = await userOnWhats(phones)
    // phones = validateNumbers.map(item => item.phone)
    return phones;

}

function formatNumber(num) {
    let cleaned = ('' + num).replace(/\D/g, '');
    // Pegue os 8 últimos números
    const lastEight = cleaned.slice(-8);
    let firstNumbers = cleaned.substring(0, cleaned.length - 8);
    firstNumbers = String(firstNumbers).replace('9', '');
    if (firstNumbers.length === 4 && firstNumbers.startsWith('55')) {
        return firstNumbers + lastEight;
    } else if (firstNumbers.length === 2) {
        return '55' + firstNumbers + lastEight;
    }
}

function formatBrNumber(num){
    const doisPrimeirosDigitos = num.slice(2, 4);
    const oitoUltimosDigitos = num.slice(-8);
    const phone = doisPrimeirosDigitos + "9" + oitoUltimosDigitos
    return phone
}

function formatCsvPhone(telefone) {
    // Remover tudo que não for número
    let apenasNumeros = telefone.replace(/\D/g, '');

    switch (apenasNumeros.length) {
        case 11:
            // Se tiver 11 dígitos, retorna como está
            return apenasNumeros;
        case 13:
            // Se tiver 13 dígitos, remove os dois primeiros
            return apenasNumeros.substring(2);
        case 10:
            // Se tiver 10 dígitos, adiciona um '9' após os dois primeiros
            return apenasNumeros.substring(0, 2) + '9' + apenasNumeros.substring(2);
        case 12:
            // Se tiver 12 dígitos, remove os dois primeiros e adiciona um '9' após os dois próximos
            return apenasNumeros.substring(2, 4) + '9' + apenasNumeros.substring(4);
        default:
            // Retorna a string original caso não atenda aos critérios
            return telefone;
    }
}

export { formatNumberArray, formatNumber, formatBrNumber, formatCsvPhone };