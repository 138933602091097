import React, {useState, useEffect, useRef} from "react";
import {Card, Col, Nav, OverlayTrigger, Row, Form, Tooltip, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast'
import {TagsInput} from "react-tag-input-component"
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import "./VideoFast.css"
import {formatNumberArray} from "../utils/formatNumber";
import { sendVideos } from "../api/axiosZap";
import { FileUpload } from 'primereact/fileupload';
import messageData from '../data/Messages.json';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import ReactPlayer from 'react-player'
import {useLiveQuery} from "dexie-react-hooks";
import db from "../data/db";
import {atualizaCsv, formattedDate} from "../utils/atualizaCsv";

export default function VideoFast() {
    const [phone, setPhone] = useState(JSON.parse(localStorage.getItem('sendList')) || []);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('Nenhum arquivo Selecionado!');
    const [caption, setCaption] = useState("")
    const [message, setMessage] = useState("");
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const msgToast = messageData.toast;
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [videoSrc , setVideoSrc] = useState("");
    const [msgSend, setMsgSend] = useState(null);

    useEffect(() => {
        if(phone) {
            setMsgSend(null)
        }
    },[phone])

    const videos = useLiveQuery(async () => {
        //
        // Query the DB using our promise based API.
        // The end result will magically become
        // observable.
        //
        const result = await db.videos.toArray();
        if(result.length > 0) {
            setVideoSrc(URL.createObjectURL(result[0].file));
            setFileName(result[0].file.name);
            setFile(result[0].file)
            setCaption(result[0].caption);
            setMessage(result[0].message);

        }
        return result;
    });

    const handleClearForm = async (e) => {
        e.preventDefault();
        setPhone([]);
        setMessage("");
        setCaption("");
        setFile(null);
        setVideoSrc(null);
        localStorage.removeItem('sendList');
        if(videos.length > 0) {
            await handleDelete(videos[0].id);
        }

    }

    const props = {
        onRemove: (file) => {
            setFile(null);
            setFileName(null);
            setVideoSrc(null);
        },
        beforeUpload: (file) => {
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                toast.error('Vídeo acima do tamanho permitido!');
                return
            }
                setFile(file);
                setFileName(file.name)
                setVideoSrc(URL.createObjectURL(file));
                return false;
        },
        file
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
            setButtonSubmit(true);
        const tloading = toast.loading(msgToast.send_message.sending);

        if(!file) {
            toast.error(msgToast.input.error_not_video)
            await toast.remove(tloading);
            setButtonSubmit(false);
            return;
        }

        const phones = await formatNumberArray(phone)
        if(phones.length === 0 ) {
            toast.error(msgToast.input.error_not_phone)
            await toast.remove(tloading);
            setButtonSubmit(false);
            return;
        }

        const phonesSemEspacaoBranco = phones.map(num => num.trim());
        const phonesEmString = phonesSemEspacaoBranco.join(',');

        const formData = new FormData();
        formData.append('phones', phonesEmString)
        formData.append("video", file);

        if(message){
            formData.append("message", message);
        }

        if (caption) {
            formData.append('caption', caption);
        }

        try{
            const response = await sendVideos(formData)
            if(response.data.length === phones.length && response.status === 200) {
                atualizaCsv(response.data);
                await toast.remove(tloading);
                setButtonSubmit(false);
                localStorage.removeItem('sendList');
                toast.success(`Mensagem(s) enviada(s)`);
                setMsgSend(`Envio finalizado: ${formattedDate()}`)
            }
        } catch (error) {
            await toast.remove(tloading);
            toast.error('Erro ao enviar mensagem!');
            setButtonSubmit(false);
        }
    };

    async function deleteItemByKeyPath(key) {
        await db.videos.delete(key);
    };
    const handleDelete = async (key) => {
        try {
            await deleteItemByKeyPath(key);
            console.log(`Item com chave ${key} foi deletado.`);
            // Atualize o estado do componente ou realize outras ações conforme necessário
        } catch (error) {
            console.error('Erro ao deletar o item:', error);
        }
    };

    const salvarMensagem = async (e) =>{
        e.preventDefault();
        await db.videos.clear();
        await db.videos.add({file: file, message: message, caption: caption});
        toast.success('Modelo de mensagem salvo!');
    };

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Mensagens</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Envio Rápido</li>
                        </ol>
                        <h4 className="main-title mb-0">Envie mensagem com vídeo!</h4>
                    </div>
                </div>
                <Row>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicPhones">
                                    <Form.Label>Telefone(s) com DDD, exemplo 51987654xxx </Form.Label>
                                    <TagsInput value={phone} onChange={setPhone} name="phone" className="form-control"
                                               id="phone" placeHolder="Digite o telefone e pressione enter" onExisting/>
                                    <Form.Text>
                                        { msgSend ?
                                            <span style={{color: "green"}}>{msgSend}</span>  : "Pressione ENTER para confirmar cada número inserido e digite a quantidade que desejar."}
                                    </Form.Text>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Button variant="primary" className="btn-icon" title="Limpar Telefones"
                                                onClick={() => setPhone([])}><i className="ri-eraser-fill"></i></Button>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicImage">
                                    <Form.Label style={{marginRight: "20px"}}>Adicione um Vídeo de até 20mb.</Form.Label>
                                    <Upload {...props} accept=".mp4" fileList={[]}>
                                        <Button type="primary" icon={<UploadOutlined />}>Selecione um vídeo</Button>

                                    </Upload>
                                </Form.Group>

                                    {
                                        videoSrc ?
                                            <Row className="d-flex justify-content-center">
                                                    <ReactPlayer controls={true} url={videoSrc} />
                                                    <Row className="d-flex justify-content-center ">
                                                        <Button style={{margin: "20px 0px", maxWidth: "50%"}}
                                                                icon={<DeleteOutlined />}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    setFile(null);
                                                                    setVideoSrc(null);
                                                                }}
                                                        >Remover Vídeo</Button>
                                                    </Row>
                                            </Row>
                                            : <div></div>
                                    }

                                <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicImage">
                                        <Form.Label>Mensagem junto ao Vídeo</Form.Label>
                                        <Form.Control as="textarea" aria-label="With textarea" rows={15}
                                                      width="100%" name="caption" value={caption}
                                                      onChange={(e) => setCaption(e.target.value)}/>
                                        <Form.Text>
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="formBasicText">
                                    <Form.Label>Mensagem Adicional</Form.Label>
                                    <Form.Control as="textarea" aria-label="With textarea" rows={15} name="message" value={message}
                                    onChange={(e) => setMessage(e.target.value)}/>
                                    <Form.Text>

                                    </Form.Text>
                                </Form.Group>
                                </Col>
                                </Row>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary me-2" disabled={buttonSubmit}>
                                        ENVIAR MENSAGEM <i className="ri-chat-smile-2-fill"></i></button>

                                    <button className="btn btn-info " onClick={salvarMensagem}>
                                        SALVAR MENSAGEM <i className="ri-save-3-line"></i></button>

                                    <button className="btn btn-secondary" onClick={handleClearForm}>
                                        DELETAR MENSAGEM <i className="ri-eraser-fill"></i></button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}