import React, {useState, useEffect, useRef} from "react";
import {Card, Col, Nav, OverlayTrigger, Row, Form, Tooltip, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast'
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {formatNumberArray, formatBrNumber} from "../utils/formatNumber";
import messageData from '../data/Messages.json';

import * as yup from 'yup';
import { contactInfo } from "../api/axiosZap";
import ImportCsv from "../components/ImportCsv";
import {Space} from "antd";


function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const yyyy = today.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
}

// Define o schema
const dataSchema = yup.array().of(
    yup.object().shape({
        updateAt: yup.string().default('SEM MENSAGEM'),
        createAt: yup.string().default(getCurrentDate()),
        tag: yup.string(),
        dataNascimento: yup
            .string()
            .notRequired(),
        telefone: yup.string().matches(/^[0-9]{11}$/, 'Deve ter 11 dígitos'),
        nome: yup.string().required(),
    })
);



export default function CreateUser() {
    async function listContacts(e) {
        e.preventDefault();
        const toastList = toast.loading("Buscando contatos...", {className: "toastCss"});
        const listContacts = await contactInfo();

        if (listContacts) {
            const newContacts = listContacts.reduce((acc, contact) => {
                const removeDoublePoints = contact.phoneNumber.split(':')[0]
                const ddd = removeDoublePoints.slice(0, 2);
                if (ddd === '55') {
                    const numberFormatted = formatBrNumber(contact.phoneNumber);
                    const newContact = {
                        nome: contact.fullName || contact.businessName || contact.pushName,
                        telefone: numberFormatted,
                        dataNascimento: '',
                        tag: 'WHATS-CONTATOS',
                        createAt: getCurrentDate(),
                        updateAt: 'SEM MENSAGEM',
                    };
                    acc.push(newContact);
                }
                return acc;
            }, []);

            // Recupera contatos do localStorage
            const storedContacts = JSON.parse(localStorage.getItem('csvData')) || [];

            // Mescla e remove duplicatas
            const mergedContacts = [...newContacts, ...storedContacts].filter(
                (contact, index, self) =>
                    index === self.findIndex((t) => (
                        t.telefone === contact.telefone
                    ))
            );

            localStorage.setItem('csvData', JSON.stringify(mergedContacts));
        }
        toast.remove(toastList);
        toast.success("Contatos recuperados com sucesso!");
    }

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Contatos</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Importar</li>
                        </ol>
                        <h4 className="main-title mb-0">Puxe contatos do seu Whatsapp ou importe por um arquivo CSV.</h4>
                    </div>

                    <div className="d-flex gap-2 mt-3 mt-md-0">
                        <a href="https://docs.google.com/spreadsheets/d/1p2KIhQC8vCAmX7KbvCNBASgqbZ4FWosKyaKaaq1cgGQ/edit?usp=sharing" className="d-flex align-items-center gap-2 btn btn-primary" target="_blank">
                            <i className="ri-share-box-line"></i>MODELO DE CSV</a>
                    </div>
                </div>
                <Row>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <h5>Clique para puxar os contatos cadastrados no seu Whatsapp!</h5>
                            <button type="submit" className="btn btn-warning me-2" onClick={listContacts}>Puxar contatos do meu Whatsapp
                            </button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <ImportCsv />
                </Col>
                </Row>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}