import React, { useState, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Modal, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "./List.css";

dayjs.extend(customParseFormat);

export default function List() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contatoNome, setContatoNome] = useState('');
    const [contatoTelefone, setContatoTelefone] = useState('');
    const [contatoDataNasc, setContatoDataNasc] = useState(null);
    const [contatoTag, setContatoTag] = useState('')
    const [contatoKey, setContatoKey] = useState('');
    const [modalSendMessage, setModalSendMessage] = useState(false);
    const navigate = useNavigate();


    let contacts = JSON.parse(localStorage.getItem('csvData')) || [];
    let data = contacts.map(contato => ({
        ...contato,
        key: contato.telefone
    }));



    const dateFormat = 'DD/MM/YYYY';
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleCancel = () => {
        setContatoKey('');
        setContatoNome('');
        setContatoTelefone('');
        setContatoDataNasc(contatoDataNasc ? dayjs(contatoDataNasc, dateFormat): null);
        setContatoTag('');
        setIsModalOpen(false);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Busca ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleUpdate = (key) => {
        setIsModalOpen(true);
        console.log(key)
        const contato = contacts.find(c => c.telefone === key);
        if(contato){
            setContatoKey(contato.telefone);
            setContatoNome(contato.nome);
            setContatoTelefone(contato.telefone);
            setContatoDataNasc(contato.dataNascimento);
            setContatoTag(contato.tag);
        } else {
            toast.error('Contato não localizado!')
        }
    }

    const dataAtual = new Date();

    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const ano = dataAtual.getFullYear();

    const horas = String(dataAtual.getHours()).padStart(2, '0');
    const minutos = String(dataAtual.getMinutes()).padStart(2, '0');

    const dataFormatada = `${dia}/${mes}/${ano} ${horas}:${minutos}`;

    const handleSave = () => {
        const contato = contacts.find(c => c.telefone === contatoKey);
        const novosDados = {
            nome: contatoNome,
            telefone: contatoTelefone,
            dataNascimento: contatoDataNasc,
            tag: contatoTag};
        if (contato) {
            // Atualizar o contato com novosDados
            Object.assign(contato, novosDados);

            // Salvar no localStorage
            localStorage.setItem('csvData', JSON.stringify(contacts));
            console.log('Salvo!');
            setIsModalOpen(false);
        } else {
            console.log('Contato não encontrado');
        }
    }

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            sorter: (a, b) => a.nome.localeCompare(b.nome),
            ...getColumnSearchProps('nome'),
        },
        {
            title: 'Telefone',
            dataIndex: 'telefone',
            ...getColumnSearchProps('telefone'),
        },
        {
            title: 'Data Nasc.',
            dataIndex: 'dataNascimento',
            ...getColumnSearchProps('dataNascimento'),
        },
        {
            title: 'Tags',
            dataIndex: 'tag',
            ...getColumnSearchProps('tag'),
        },
        {
            title: 'Última Atualização',
            dataIndex: 'updateAt',
            ...getColumnSearchProps('updateAt'),
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => handleUpdate(record.key)}>Editar</Button>
                </Space>
            ),
        },
    ];
    const onChangeDate = (date, dateString) => {
        setContatoDataNasc(dateString)
    };

    function removerContatos(telefones) {
        // Recuperar o array do localStorage e deserializá-lo
        const contatos = JSON.parse(localStorage.getItem("csvData")) || [];

        // Filtrar o array para remover os contatos especificados
        const contatosAtualizados = contatos.filter(contato => !telefones.includes(contato.telefone));

        // Serializar e salvar o array atualizado de volta no localStorage
        localStorage.setItem("csvData", JSON.stringify(contatosAtualizados));
        start();
        toast.success("Contatos removidos!")
    }

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Contatos</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Todos os Contatos</li>
                        </ol>
                        <h4 className="main-title mb-0">Lista de todos os contatos salvos neste computador!</h4>
                    </div>
                    <div className="d-flex gap-2 mt-3 mt-md-0">
                        <button type="button" className="d-flex align-items-center gap-2 btn btn-primary" disabled={!hasSelected}
                        onClick={(e) => {
                            e.preventDefault();
                            removerContatos(selectedRowKeys);
                        }}>
                            <i class="ri-delete-bin-line"></i>Deletar Selecionados</button>
                        <button type="button" className="d-flex align-items-center gap-2 btn btn-danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    // Exibe o alerta de confirmação
                                    const confirmar = window.confirm("Tem certeza de que deseja remover TODOS os contatos?");

                                    // Se o usuário confirmar, prossegue com a remoção
                                    if (confirmar) {
                                        localStorage.removeItem("csvData")
                                        start();
                                    }
                                }}>
                            <i className="ri-delete-bin-2-fill"></i>Deletar Todos Cadastros</button>
                    </div>

                </div>
                <Row>
                    <Card>
                        <Card.Body>

                            <Button type="primary" style={{marginRight: "20px"}} disabled={!hasSelected} onClick={(e) => {
                                e.preventDefault();
                                if(selectedRowKeys.length < 51){
                                    localStorage.setItem("sendList", JSON.stringify(selectedRowKeys));
                                    setModalSendMessage(!modalSendMessage);
                                } else {
                                    toast.error("Selecione lotes de até 50 contatos por vez!")
                                }
                            }}>
                                {hasSelected ? `Enviar para ${selectedRowKeys.length} contato(s)` : 'Nenhum Contato Selecionado!' }
                            </Button>

                            <Button type="primary" size="small" onClick={start} disabled={!hasSelected} loading={loading} >
                                Limpar Seleção
                            </Button>

                            <span
                                style={{
                                    marginLeft: 8,
                                }}
                            >
                            </span>
                            <Table rowSelection={rowSelection} columns={columns} dataSource={data}/>
                        </Card.Body>
                    </Card>
                </Row>
                <Modal title="Editar Contato" open={isModalOpen} onCancel={handleCancel} onOk={handleSave}>
                    <Row>
                        <Space direction="vertical" size={10}>
                            <Input type="text"
                                   name="nome"
                                   value={contatoNome}
                                   onChange={(e) => setContatoNome(e.target.value)}
                                   placeholder="Nome do Contato">
                            </Input>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Input type="text"
                                           name="telefone"
                                           value={contatoTelefone}
                                           onChange={(e) => setContatoTelefone(e.target.value)}
                                           placeholder="Telefone com DDD">
                                    </Input>
                                </Col>
                                <Col xs={12} md={6}>
                                    <DatePicker style={{ width: '100%' }}
                                                value={contatoDataNasc ? dayjs(contatoDataNasc, dateFormat): null}
                                                defaultValue={contatoDataNasc ? dayjs(contatoDataNasc, dateFormat): null}
                                                name="dataNasc"
                                                format={dateFormat}
                                                onChange={onChangeDate}
                                    />
                                </Col>
                            </Row>
                            <Input type="text"
                                   name="tag"
                                   value={contatoTag} onChange={(e) => setContatoTag(e.target.value)}
                                   placeholder="Tag(s)">
                            </Input>
                        </Space>
                    </Row>
                </Modal>
                <Modal
                    id="modalSendMessage"
                    open={modalSendMessage}
                    title="Escolha o tipo de mensagem que deseja enviar!"
                    width={630}
                    onCancel={() => {
                        localStorage.removeItem('sendList');
                        setModalSendMessage(!modalSendMessage);
                    }}
                    footer={(_, { OkBtn, CancelBtn }) => (
                        <>
                            <Button onClick={() => navigate('/text')}>Somente Texto</Button>
                            <Button onClick={() => navigate('/image')}>Uma Imagem</Button>
                            <Button onClick={() => navigate('/video')}>Um Vídeo</Button>
                            <Button onClick={() => navigate('/doc')}>Um Documento</Button>
                            <CancelBtn />
                        </>
                    )}
                >
                </Modal>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}
