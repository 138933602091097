import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh',{
            withCredentials: true
        });
        setAuth(prev => {
            // console.log('RefreshToken: ', JSON.stringify(prev));
            // console.log('RefreshToken-AcccessToken: ', response.data.accessToken);
            return {...prev,
                email: response.data.email,
                username: response.data.username,
                roles: response.data.roles,
                apiToken: response.data.apiToken,
                accessToken: response.data.accessToken}
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;