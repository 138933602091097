// db.js
import Dexie from 'dexie';

const db = new Dexie('acelera_app');
db.version(1).stores({
    texto: '++id, message',
    imagens: '++id, file, message, caption',
    videos: '++id, file, message, caption',
    documentos: '++id, file, message'
});
export default db;
