import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {Button, Card, Col, Form, Modal, Nav, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Radio } from 'react-loader-spinner'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { generateQrCode, clientConnect, clientDisconnect, clientStatus, clientLogout} from "../api/axiosZap";
import toast, { Toaster } from 'react-hot-toast'
import { Empty } from 'antd';

export default function UserDashboard() {
  const axiosPrivate = useAxiosPrivate()
  const [news, setNews] = useState(null);
  const [qrcode, setQrcode] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful},
    reset
  } = useForm({ defaultValues: { name: "", email: "", password: "" } })
  const [isConnected, setIsConnected] = useState( )
  const currentDate = new Date().toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  const MAX_ATTEMPTS = 1;
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function generateQr(e) {
    e.preventDefault();
    let success = false;
    let attempts = 0;
    const toastQR = toast.success('Gerando QrCode', { className: 'toastCss' })
    while (attempts < MAX_ATTEMPTS) {
      try {
        const response = await generateQrCode();
        if (response.code && response.code !== "") {
          setQrcode(response.code);
          setModalShow(!modalShow);
          success = true;
        } else if (response.detail === "The user is already connected to the service. Reconnection is not required at this moment.") {
          toast.success("Você está conectado!", { className: "toastCss" });
          success = true;
        }
      } catch (error) {
        if (error.response.data.detail === "Already Logged in") {
          toast.success("Você está conectado!", { className: "toastCss" });
          success = true;
        } else {

          toast.error("Tente gerar novamente!")
        }
      }
      attempts++;
      await sleep(2000)
    }
    toast.remove(toastQR)
    return success
  }


  const searchStatus = async () => {
    try {
      const response = await clientStatus()
          .then((response) => {
            if(response['code'] === 200 && response['success'] === true
                && response['data']['LoggedIn'] === false)
            {
              setIsConnected(false)
              toast.error('Por favor gere um novo QR Code')
            } else {
              setIsConnected(true)
            }
          })
    } catch (err) {
      const reconnect = await clientConnect()
      if(reconnect['code'] === 200 && reconnect['success'] === true
          && reconnect['data']['Connected'] === true){
        setIsConnected(true)
      }
    }
  }

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await axiosPrivate.get('/newsletter');
        if(response.data.length > 0){
          setNews(response.data);
          console.log(response.data)
        }
      } catch (error) {
        console.error('Erro ao buscar as últimas notícias', error);
      }
    }

    fetchNews();

  }, []);

  useEffect(() => {
    const tokenLocal = localStorage.getItem('token')
    try {
      if(tokenLocal){
        searchStatus();
      }
    } catch (err) {
      setTimeout(() => {
        searchStatus();
      }, 2000)
    }
  },[])

  function modalHandleClose(){
    setModalShow(!modalShow);
    searchStatus();
  }

  return (
    <React.Fragment>
      <Toaster />
      <Header />
      <Card className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Usuário</li>
            </ol>
            <h4 className="main-title mb-0">Bem vindo ao seu Dashboard</h4>
          </div>
        </div>
        <Row className="g-3">
          {[
            {
              "label": "Data",
              "icon": "ri-inbox-line",
              "value": `${currentDate}`,
              "percent": "",
              "status": "up"
            }
          ].map((card, index) => (
            <Col xs="12" xl="4" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                  <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                  <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent} </span> {card.message}</small>
                </Card.Body>
              </Card>
            </Col>

          ))}
          <Col xs="12" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Card.Title as="label" className="fs-sm fw-medium mb-1">Status do Serviço</Card.Title>
                {isConnected ?
                    <>
                      <h3 className="card-value mb-1">
                         <Radio width={50} height={50}/>
                      </h3>
                      <h6>Seu WhatsApp está conectado!</h6>
                    </>
                  :
                  <>
                    <h3 className="card-value mb-1">
                      <i className="ri-wifi-off-fill" style={{fontSize: "40px"}}></i>
                    </h3>
                    <h6>Seu WhatsApp está desconectado!</h6>
                  </>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" xl="4" >
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-grid gap-4">

                    <Button variant="warning" onClick={generateQr}>Gerar Novo QrCode</Button>
                    <h6 className="fw-semibold text-dark text-center mb-1">Clique para gerar um QrCode ou para verificar se seu número está conectado!</h6>
                  </div>
                </Card.Body>
              </Card>

            </Col>
        </Row>
        <Row className="g-3" style={{marginTop: "20px"}}>
          {news ?
              news.map((item, index) => (
                  <Col xs="12" xl="4" key={index}>
                      <Card className="card-event">
                        <Link to="#"><img src={item.image} className="card-img" alt="" /></Link>
                        <Card.Body style={{height: '80px'}}>
                          <h6 style={{textAlign: "center"}}>{item.name}</h6>
                        </Card.Body>
                        <Card.Footer>
                          {
                            item.text_button === "baixar_imagem"
                                ? <a href={item.image} download target="_blank" className="btn btn-primary">CLIQUE PARA BAIXAR A IMAGEM!</a>
                                : <a href={item.url} className="btn btn-primary">{item.text_button}</a>
                          }
                        </Card.Footer>
                      </Card>
                    </Col>
                ))
              : <> <Empty /> </>
          }

        </Row>
        <Footer />
      </Card>

      <Modal show={modalShow} onHide={modalHandleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Conecte seu Whatsapp!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{qrcode ? (<img src={qrcode} />) : (<></>)}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={modalHandleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}