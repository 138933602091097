import {format} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
function adicionarNove(phone) {
    const comNove = phone.slice(0, 4) + '9' + phone.slice(4);
    return comNove.slice(2);
}

// Função para formatar o timestamp
function formatarTimestamp(timestamp) {
    const date = new Date(timestamp);
    const formatter = new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
    return formatter.format(date);
}

function atualizaCsv(data) {
    const resultado = data
        .filter(item => item.details === 'Sent')
        .map(item => ({
            phone: adicionarNove(item.phone),
            timestamp: formatarTimestamp(item.timestamp)
        }));

    const ContatosSalvos = JSON.parse(localStorage.getItem('csvData'));
    if(ContatosSalvos) {
        resultado.forEach(dadoAtualizado => {
            const contato = ContatosSalvos.find(contato => contato.telefone === dadoAtualizado.phone);
            if (contato) {
                contato.updateAt = `Disparo: ${dadoAtualizado.timestamp}`;
            }
        });
        localStorage.setItem('csvData', JSON.stringify(ContatosSalvos));
        return true
    }
}

const formattedDate = () => {
    // Obter a data e hora atual
    const now = new Date();

    // Formatar a data e hora
    // 'dd/MM/yyyy HH:mm' é o padrão para dia/mês/ano horas:minutos
    return format(now, 'dd/MM/yyyy HH:mm', {locale: ptBR});
}

export { atualizaCsv, formattedDate }