import React, {useState, useEffect} from "react";
import { Card, Col, Nav, OverlayTrigger, Row, Form, Tooltip, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {sendMessages, validaChip} from "../api/axiosZap";
import toast, {Toaster} from 'react-hot-toast'
import {TagsInput} from "react-tag-input-component"
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import messageData from '../data/Messages.json';
import { Button, message, Upload } from 'antd';
import {atualizaCsv, formattedDate} from "../utils/atualizaCsv";
import {format} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import db from "../data/db";
import {formatNumberArray} from "../utils/formatNumber";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


export default function ValidadorChip() {
    const axiosPrivate = useAxiosPrivate()
    const [phone, setPhone] = useState([]);
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const msgToast = messageData.toast;
    const [msgSend, setMsgSend] = useState(null)


    useEffect(() => {
        async function validador() {
            try {
                const response = await axiosPrivate.get('/validador');
                if (response) {
                    setPhone(response.data)
                }
            } catch (error) {
                console.error('Erro ao buscar as últimas notícias', error);
            }
        }
        validador();
    },[]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonSubmit(true)
        const tloading = toast.loading(msgToast.send_message.sending);
        const phones = await formatNumberArray(phone);

        if(phones.length === 0) {
            toast.error(msgToast.input.error_not_phone)
            await toast.remove(tloading)
            setButtonSubmit(false)
            return
        }


        try {
            const response = await validaChip(phones);

            await toast.remove(tloading);
            setButtonSubmit(false);
            setMsgSend(`Envio finalizado: ${formattedDate()}`)
            toast.success(msgToast.send_message.success);

        } catch (error) {
            await toast.remove(tloading);
            toast.error(msgToast.send_message.error);
            setButtonSubmit(false);
        }
    }

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Mensagens</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Validador de Chip</li>
                        </ol>
                        <h4 className="main-title mb-0">Envie mensagem aleatórias para números seguros!</h4>
                    </div>
                </div>
                <Row>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicPhones">
                                    <Form.Label>Telefone(s) com DDD, exemplo 51987654xxx </Form.Label>
                                    <TagsInput value={phone} onChange={setPhone}  className="form-control"
                                               placeHolder="Digite o telefone e pressione enter"
                                    />
                                    <Form.Text>
                                        { msgSend ?
                                            <span style={{color: "green"}}>{msgSend}</span>  : "Pressione ENTER para confirmar cada número inserido e digite a quantidade que desejar."}
                                    </Form.Text>
                                </Form.Group>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary me-2" disabled={buttonSubmit}>
                                        INICIAR VALIDAÇÃO <i className="ri-chat-smile-2-fill"></i></button>

                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}