import React, {useRef, useState, useEffect} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";

import axios from "../api/axios";
import {dashboardMenuSuperAdmin, dashboardMenuAdmin} from "../data/Menu";

const LOGIN_URL = '/auth/login';

export default function Login() {
    const {setAuth, persist, setPersist} = useAuth();


    const navigate = useNavigate();
    const location = useLocation();
    const {from} = location.state?.from?.pathname || "/";

    const userRef = useRef(null);
    const errRef = useRef(null);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user === '' || pwd === '') {
            setErrMsg('Preencha todos os campos');
        } else {
            try {
                const response = await axios.post(LOGIN_URL, JSON.stringify({email: user, password: pwd}),
                    {
                        headers: {'Content-Type': 'application/json'}
                    })
                const accessToken = response?.data?.accessToken;
                const roles = response?.data?.roles;
                const username = response?.data?.name;
                const email = response?.data?.email;
                const apiToken = response?.data?.apiToken;
                setAuth({user, username, email, pwd, accessToken, roles, apiToken});
                setUser('');
                setPwd('');
                await localStorage.setItem("persist", "true");
                await localStorage.setItem("token", apiToken);
                const foundEntry = Object.entries(roles).find(([key, value]) => value === 1000);
                if (foundEntry) {
                    await localStorage.setItem('menuDashboard', JSON.stringify(dashboardMenuSuperAdmin));
                    navigate('/dashboard/super-admin', {replace: true});
                } else {
                    await localStorage.setItem('menuDashboard', JSON.stringify(dashboardMenuAdmin));
                    navigate('/dashboard', {replace: true});
                }
                //navigate(from, {replace: true});
            } catch (err) {
                if (err?.response?.status === 400) {
                    setErrMsg('Servidor indisponível, tente novamente mais tarde');
                } else if (err?.response?.status === 401) {
                    setErrMsg('Não autorizado');
                } else {
                    setErrMsg('Falha no login, tente novamente mais tarde');
                }
                errRef.current.focus();
            }
        }
    }


    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                <Col md="7" lg="5" xl="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Link to="/" className="header-logo mb-5">Acelera Apps</Link>
                            <Card.Title>Login</Card.Title>
                            <Card.Text>
                                <span ref={errRef} className={errMsg ? "errmsg" : "offscreen"}
                                      aria-live="assertive">{errMsg}</span>
                            </Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite seu email"
                                        ref={userRef}
                                        autoComplete="off"
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <Form.Label className="d-flex justify-content-between">
                                        Senha
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Digite sua senha"
                                        value={pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                        required
                                    />
                                </div>
                                <Button type="submit" className="btn-sign">Login</Button>


                            </Form>
                        </Card.Body>

                    </Card>
                </Col>
                <Col className="d-none d-lg-block">
                    <img src={bg1} className="auth-img" alt=""/>
                </Col>
            </Row>
        </div>
    )
}