import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({allowedRoles}) => {
    const {auth} = useAuth();
    const location = useLocation();

    return (
            Object.entries(auth?.roles ?? {}).find(([key, value]) => allowedRoles?.includes(value))
            ? <Outlet to="/"/>
            : auth?.accessToken
                ? <Navigate to="/unauthorized" state={{from: location}} replace/>
                : window.location.href = '/login'
    )
}

export default RequireAuth;