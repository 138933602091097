import React, {useState} from 'react';
import * as yup from 'yup';
import toast, {Toaster} from 'react-hot-toast'
import Header from "../layouts/Header";
import {Link} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";

// // Define o schema
// const dataSchema = yup.array().of(
//     yup.object().shape({
//         nome: yup.string().required(),
//         telefone: yup.string().matches(/^[0-9]{11}$/, 'Deve ter 11 dígitos'),
//         dataNascimento: yup
//             .string()
//             .notRequired(),
//         tag: yup.string()
//     })
// );

function CreateContact() {
    const [data, setData] = useState(JSON.parse(localStorage.getItem('csvData')) || [])
    // Estados para inserção manual
    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [tag, setTag] = useState('')
    const [createAt] = useState('')
    const [updateAt] = useState('')

    function getCurrentDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
        const yyyy = today.getFullYear();
        return `${dd}-${mm}-${yyyy}`;
    }

    // ... Outros handlers ...
    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-').map(str => parseInt(str, 10));
        return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
    }

    const handleManualSubmit = (e) => {
        e.preventDefault()

        const newEntry = {nome, telefone, dataNascimento, tag, createAt, updateAt};
        console.log(newEntry)
        if (newEntry.dataNascimento) {
            newEntry.dataNascimento = formatDate(newEntry.dataNascimento)
        } else {
            newEntry.dataNascimento = ''
        }

        newEntry.createAt = getCurrentDate()
        newEntry.updateAt = 'SEM MENSAGEM'
        // Verificar se a entrada com o mesmo valor de telefone já existe
        const isDuplicate = data.some(item => item.telefone === telefone);

        if (isDuplicate) {
            toast.error('Número já cadastrado!')
            return;
        }
        // Valide a nova entrada
        yup.object().shape({
            nome: yup.string().required(),
            telefone: yup.string().matches(/^[0-9]{11}$/, 'Deve ter 11 dígitos'),
            dataNascimento: yup.string()
                .nullable()
                .notRequired(),
            tag: yup.string(),
            createAt: yup.string(),
            updateAt: yup.string().notRequired()
        })
            .validate(newEntry)
            .then(() => {
                // 1. Pegar os dados existentes no localStorage (se houver)

                const storedData = localStorage.getItem('csvData');
                let updatedData = storedData ? JSON.parse(storedData) : [];

                // 2. Mesclar os dados existentes com a nova entrada
                updatedData = [...updatedData, newEntry];

                // 3. Salvar os dados atualizados no localStorage
                localStorage.setItem('csvData', JSON.stringify(updatedData));

                // Atualizar o estado local com os dados atualizados
                setData(updatedData);
                toast.success('Cadastro adicionado com sucesso!')
                // Limpe os campos após a submissão bem-sucedida
                setNome('');
                setTelefone('');
                setDataNascimento('');
                setTag('');
            })
            .catch(error => {
                toast.error(`${error}`)
            });
    };

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Contatos</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Cadastro</li>
                        </ol>
                        <h4 className="main-title mb-0">Cadastre um novo contato!</h4>
                    </div>
                </div>
                <Row>
                    <Col md={6} xs={12}>
                        <Card>
                            <Card.Body>
                                <div className="col-md-12 col-xl-12 grid-margin stretch-card">

                                    <h6 className="card-title">Cadastrar</h6>
                                    <form className="forms-sample">
                                        <div className="mb-3">
                                            <label htmlFor="nome" className="form-label">Nome</label>
                                            <input className="form-control" value={nome}
                                                   onChange={e => setNome(e.target.value)} name="nome" id="nome"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="telefone" className="form-label">Telefone</label>
                                            <input className="form-control" value={telefone}
                                                   onChange={e => setTelefone(e.target.value)} name="telefone"
                                                   id="telefone" minLength={11} maxLength={11}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="dataNascimento" className="form-label">Data
                                                Nascimento</label>
                                            <input type="date" className="form-control" placeholder="dd-mm-yyyy"
                                                   value={dataNascimento}
                                                   onChange={e => setDataNascimento(e.target.value)}
                                                   name="dataNascimento" id="dataNascimento"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tag" className="form-label">Tag</label>
                                            <input className="form-control" value={tag}
                                                   onChange={e => setTag(e.target.value)} name="tag" id="tag"/>
                                        </div>
                                        <button className="btn btn-primary" onClick={handleManualSubmit}>Adicionar
                                        </button>
                                    </form>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Card>
        </React.Fragment>
    );
}

export default CreateContact