import React, {useEffect, useState} from "react"
import { useNavigate,Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/img/user.png";
import notification from "../data/Notification";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import { Radio } from 'react-loader-spinner'
import './Header.css'


export default function Header() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/login');
  }


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  function NotificationList() {
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div className={(item.status === "online") ? "avatar online" : "avatar"}>{item.avatar}</div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    });

    return (
      <ul className="list-group">
        {notiList}
      </ul>
    );
  }


  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      <div className="me-auto">

      </div>



      {/*<Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">*/}
      {/*  <Dropdown.Toggle as={CustomToggle}>*/}
      {/*    <small>3</small><i className="ri-notification-3-line"></i>*/}
      {/*  </Dropdown.Toggle>*/}
      {/*  <Dropdown.Menu className="mt-10-f me--10-f">*/}
      {/*    <div className="dropdown-menu-header">*/}
      {/*      <h6 className="dropdown-menu-title">Notifications</h6>*/}
      {/*    </div>*/}
      {/*    {NotificationList()}*/}
      {/*    <div className="dropdown-menu-footer"><Link to="#">Show all Notifications</Link></div>*/}
      {/*  </Dropdown.Menu>*/}
      {/*</Dropdown>*/}

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><img src={userAvatar} alt="" /></div>
            <h5 className="mb-1 text-dark fw-semibold">{auth?.username}</h5>
            <p className="fs-sm text-secondary">{auth?.email}</p>

            <nav className="nav">
              <Link to="/profile"><i className="ri-edit-2-line"></i> Editar Perfil</Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to="" onClick={signOut}><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}