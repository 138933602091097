import React, { useState, useEffect } from "react";
import {Button, Card, Col, Nav, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Avatar from "../components/Avatar";
import ReactApexChart from "react-apexcharts";
import { Bar } from 'react-chartjs-2';
import { dp1, dp2, dp3 } from "../data/DashboardData";
import { VectorMap } from "@react-jvectormap/core";
import { usAea } from "@react-jvectormap/unitedstates";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import {Grid, _ } from "gridjs-react";

export default function SuperAdmin() {
  const axiosPrivate = useAxiosPrivate();
  const [lojas, setLojas] = useState(0);
  const [values, setValues] = useState(89.00);
  const [lojasDados, setLojasDados] = useState([] );
  const currentDate = new Date().toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  const currentSkin = (localStorage.getItem('skin-mode'))? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  useEffect( () => {
  const numLojas = async () => {
    await axiosPrivate.get('/establishments').then((response) => {
      setLojas(response.data.length);
      setLojasDados(response.data);
    })

  }
  numLojas().catch(console.error);
  },[])

  const handleEdit = (id) => {
    console.log("Editar item com ID:", id);
    // Implemente a lógica de edição aqui
  };

  const handleDelete = (id) => {
    console.log("Deletar item com ID:", id);
    // Implemente a lógica de exclusão aqui
  };

  const renderActionButton = (item) => (
      <>
        <button onClick={() => handleEdit(item.id)}>Editar</button>
        <button onClick={() => handleDelete(item.id)}>Excluir</button>
      </>
  );



  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Card className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Super Admin</li>
            </ol>
            <h4 className="main-title mb-0">Bem vindo ao seu Dashboard</h4>
          </div>


        </div>
        <Row className="g-3">
          {[
            {
              "label": "Data",
              "icon": "ri-inbox-line",
              "value": `${currentDate}`,
              "percent": "Fechamento todo dia 05",
              "status": "up"
            },
            {
              "label": "Lojas",
              "icon": "ri-shopping-bag-3-line",
              "value": `${lojas}`,
              "percent": "Total de Lojas ativas",
              "status": "up",
              "message": ""
            }, {
              "label": "A Receber",
              "icon": "ri-briefcase-4-line",
              "value": `R$ ${lojas * values},00`,
              "percent": "R$ 89.00",
              "status": "up",
              "message": "Valor por Loja"
            },  {
              "label": "Inadimplentes",
              "icon": "ri-bar-chart-box-line",
              "value": "0",
              "percent": "Nenhuma loja esta inadimplente",
              "status": "up"
            }
          ].map((card, index) => (
            <Col xs="6" xl="3" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                  <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                  <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent} </span> {card.message}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}

        </Row>
        <Row className="g-3 mt-3">
          <Col xs="12" sm="12" xl>
            <Card className="card-one">
            <Card.Body>
              <Grid
                  data={lojasDados.map(item => [item.name, item.CNPJ,
                    _(<button className={"btn btn-primary"} onClick={() => alert(item.id)}>Editar</button>)])}
                  columns={['Nome', 'CNPJ', 'Acões']}
                  search={true}
                  pagination={true}
                  sort={true}
                  resizable={true}
                  className={{
                    table: 'table table-bordered mb-0'
                  }}
              />
            </Card.Body>
          </Card>
          </Col>
        </Row>
        <Footer />
      </Card>
    </React.Fragment>
  )
}