import React, {useState, useEffect, useRef} from "react";
import {Card, Row, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast'
import {TagsInput} from "react-tag-input-component"
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import "./VideoFast.css"
import {formatNumberArray} from "../utils/formatNumber";
import { sendDocs } from "../api/axiosZap";
import messageData from '../data/Messages.json';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import {useLiveQuery} from "dexie-react-hooks";
import db from "../data/db";
import { CloudUploadOutlined } from '@ant-design/icons';
import {atualizaCsv, formattedDate} from "../utils/atualizaCsv";

export default function DocFast() {
    const [phone, setPhone] = useState(JSON.parse(localStorage.getItem('sendList')) || []);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState(null);
    const [message, setMessage] = useState();
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const msgToast = messageData.toast;
    const [msgSend, setMsgSend] = useState(null)

    const docs = useLiveQuery(async () => {
        //
        const result = await db.documentos.toArray();
        if(result.length > 0) {
            setFileName(result[0].file.name);
            setFile(result[0].file)
            setMessage(result[0].message);
        }
        return result;
    });

    useEffect(() => {
        if(phone) {
            setMsgSend(null)
        }
    },[phone])


    const handleClearForm = async (e) => {
        e.preventDefault();
        const confirmacao = window.confirm("Tem certeza que deseja deletar o modelo de mensagem?");
        if(confirmacao){
            setMessage('');
            setFile(null);
            setFileName(null);
            await db.documentos.clear()
        }

    }

    const props = {
        onRemove: (file) => {
            setFile(null);
            setFileName(null);
        },
        beforeUpload: (file) => {
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                toast.error('Arquivo acima do tamanho permitido!');
                return
            }
                setFile(file);
                setFileName(file.name)
                return false;

        },
        file
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
            setButtonSubmit(true);
        const tloading = toast.loading(msgToast.send_message.sending);

        if(!file) {
            toast.error(msgToast.input.error_not_document)
            await toast.remove(tloading);
            setButtonSubmit(false);
            return;
        }


        const phones = await formatNumberArray(phone)
        if(phones.length === 0 ) {
            toast.error(msgToast.input.error_not_phone)
            await toast.remove(tloading);
            setButtonSubmit(false);
            return;
        }

        const phonesSemEspacaoBranco = phones.map(num => num.trim());
        const phonesEmString = phonesSemEspacaoBranco.join(',');

        const formData = new FormData();
        formData.append('phones', phonesEmString)
        formData.append("document", file);
        formData.append("fileName", file.name);

        if(message){
            formData.append("message", message);
        }


        try{
            const response = await sendDocs(formData)
            if(response.data.length === phones.length && response.status === 200) {
                atualizaCsv(response.data);

                await toast.remove(tloading);
                setButtonSubmit(false);
                localStorage.removeItem('sendList');
                toast.success(`Mensagem(s) enviada(s)`);
                setMsgSend(`Envio finalizado: ${formattedDate()}`)
            }
        } catch (error) {
            await toast.remove(tloading);
            toast.error('Erro ao enviar mensagem!');
            setButtonSubmit(false);
        }
    }

    const salvarMensagem = async (e) =>{
        e.preventDefault();
        await db.documentos.clear();
        await db.documentos.add({file: file, message: message});
        toast.success('Modelo de mensagem salvo!');
    }

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Mensagens</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Envio Rápido</li>
                        </ol>
                        <h4 className="main-title mb-0">Envie mensagem com documentos!</h4>
                    </div>
                </div>
                <Row>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicPhones">
                                    <Form.Label>Telefone(s) com DDD, exemplo 51987654xxx </Form.Label>
                                    <TagsInput value={phone} onChange={setPhone} name="phone" className="form-control"
                                               id="phone" placeHolder="Digite o telefone e pressione enter" onExisting/>
                                    <Form.Text>
                                        { msgSend ?
                                            <span style={{color: "green"}}>{msgSend}</span>  : "Pressione ENTER para confirmar cada número inserido e digite a quantidade que desejar."}
                                    </Form.Text>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Button  className="btn-icon" title="Limpar Telefones"
                                                 onClick={(e) => {
                                                     e.preventDefault();
                                                     setPhone([]);
                                                     localStorage.removeItem("sendList");
                                                 }}><i className="ri-eraser-fill"></i></Button>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicImage">
                                    <Form.Label style={{marginRight: "20px"}}>Adicione um Arquivo de até 20mb.</Form.Label>
                                    <Upload {...props} accept=".pdf,.xls, .xlsx,.csv, .pptx, .ppt" maxCount={1}>
                                        <Button type="primary" icon={<UploadOutlined />}>Selecione um Arquivo</Button>

                                    </Upload>
                                </Form.Group>
                                {
                                    fileName ?
                                        <Row className="d-flex justify-content-center">
                                            <p className={"text-center"}><CloudUploadOutlined  style={{ fontSize: '48px' }}/></p>
                                            <p className={"text-center"}>{fileName}</p>
                                            <Row className="d-flex justify-content-center ">
                                                <Button style={{margin: "20px 0px", maxWidth: "50%"}}
                                                        icon={<DeleteOutlined />}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setFile(null);
                                                            setFileName(null);
                                                            let btnRemove = document.querySelector('button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only[title="Remove file"]');
                                                            if (btnRemove) {
                                                                btnRemove.click();
                                                            } else {
                                                                console.log("Botão não encontrado");
                                                            }
                                                        }}
                                                >Remover Documento</Button>
                                            </Row>
                                        </Row>
                                        : <div></div>
                                }
                                <Form.Group className="mb-3" controlId="formBasicText">
                                    <Form.Label>Digite sua Mensagem</Form.Label>
                                    <Form.Control as="textarea" aria-label="With textarea" rows={15} name="message"  value={message}
                                                  onChange={(e) => setMessage(e.target.value)}/>
                                    <Form.Text>

                                    </Form.Text>
                                </Form.Group>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary me-2" disabled={buttonSubmit}>
                                        ENVIAR MENSAGEM <i className="ri-chat-smile-2-fill"></i></button>

                                    <button className="btn btn-info " onClick={salvarMensagem}>
                                        SALVAR MENSAGEM <i className="ri-save-3-line"></i></button>

                                    <button className="btn btn-secondary" onClick={handleClearForm}>
                                        DELETAR MENSAGEM <i className="ri-eraser-fill"></i></button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}