import React from "react";
import {Routes, Route} from "react-router-dom";
import Main from './layouts/Main';
import PersistLogin from "./components/PersistLogin";

import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized";

// Import Super Admin
import Dashboard from "./dashboard/SuperAdmin";
import User from "./pages/SuperAdmin/User";

// Import User
import UserDashboard from "./dashboard/userDashboard";
import ImageFast from "./pages/ImageFast";
import TextFast from "./pages/TextFast";
import VideoFast from "./pages/VideoFast";
import DocFast from "./pages/DocFast";
import List from "./pages/List";
import CreateContact from "./pages/CreateContact";
import ImportContact from "./pages/ImportContact";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

// Context
import RequireAuth from "./components/RequireAuth";
import NotFound from "./pages/NotFound";
import ValidadorChip from "./pages/ValidadorChip";



// set skin on load
window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("skin-mode");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-skin", skinMode);
    }
});

const ROLES_LIST = {
    "Admin": 1000,
    "User": 2000,
    "Recurring": 3000,
    "Lifetime": 4000
}

export default function App() {
    return (
        <Routes>
                    <Route element={<PersistLogin />}>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={<Login/>}/>
                        {/* SUPER ADMIN ROUTES */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]}/>}>
                            <Route path="/" element={<Main />}>
                                <Route path="/dashboard/super-admin" element={<Dashboard/>}/>
                                <Route path="/users/super-admin" element={<User/>}/>

                            </Route>
                        </Route>
                        {/* ADMIN ROUTES */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.User]}/>}>
                            <Route path="/" element={<Main />}>
                                <Route path="/dashboard" element={<UserDashboard />}/>
                                <Route path="/text" element={<TextFast />}/>
                                <Route path="/image" element={<ImageFast />}/>
                                <Route path="/video" element={<VideoFast />}/>
                                <Route path="/doc" element={<DocFast />}/>
                                <Route path="/list" element={<List />}/>
                                <Route path="/create/contact" element={<CreateContact />}/>
                                <Route path="/import/contact" element={<ImportContact />}/>
                                <Route path="/validador/chip" element={<ValidadorChip />}/>
                            </Route>
                        </Route>
                    </Route>

            <Route path="/unauthorized" element={<Unauthorized/>}/>
            <Route path="/*" element={<NotFound />}/>
        </Routes>
    );
}