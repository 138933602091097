import React, { useState, useRef, useContext } from 'react';
import Papa from 'papaparse';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import {formatCsvPhone} from '../utils/formatNumber'
function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const yyyy = today.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
}
// Define o schema
const dataSchema = yup.array().of(
    yup.object().shape({
        updateAt: yup.string().default('SEM MENSAGEM'),
        createAt: yup.string().default(getCurrentDate()),
        tag: yup.string(),
        dataNascimento: yup
            .string()
            .notRequired(),
        telefone: yup.string().required()
            .transform((value, originalValue) => formatCsvPhone(originalValue)),
        nome: yup.string().required(),
    })
);

function ImportCsv() {
    const [data, setData] = useState( JSON.parse(localStorage.getItem('csvData')) || [])
    const [selectedFile, setSelectedFile] = useState(null)
    const fileInputRef = useRef(null);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleSubmit = () => {
        if (selectedFile) {
            Papa.parse(selectedFile, {
                quotes: true,
                complete: (result) => {
                    dataSchema
                        .validate(result.data)
                        .then((validatedData) => {
                            // Recupere os dados existentes do localStorage
                            const storedData = localStorage.getItem('csvData');
                            let combinedData = storedData ? JSON.parse(storedData) : [];

                            // Combine os novos dados com os dados existentes
                            validatedData.forEach(newItem => {
                                const exists = combinedData.some(
                                    existingItem => existingItem.telefone === newItem.telefone
                                );
                                if (!exists) {
                                    combinedData.push(newItem);
                                }
                            });
                            fileInputRef.current.value = ''
                            localStorage.setItem('csvData', JSON.stringify(combinedData));
                            setData(combinedData);
                            toast.success('Arquivo importado com sucesso!')
                        })
                        .catch((error) => {
                            console.error('Erro na validação:', error);
                            toast.error('Erro ao importar arquivo!')
                        });
                },
                header: true,
            });
        }
    };

    return (
        <div className="col-md-12 grid-margin stretch-card">
            <Toaster />
            <div className="card">
                <div className="card-body">

                    <div className="mb-3">
                        <label htmlFor="csvUpload" className="form-label">Upload CSV:</label>
                        <input className="form-control"
                               type="file"
                               id="csvUpload"
                               accept=".csv"
                               ref={fileInputRef}
                               onChange={handleFileChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary me-2" onClick={handleSubmit}>Enviar</button>
                </div>
            </div>
        </div>
    );
}
export default ImportCsv
