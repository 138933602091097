import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {Button, Card, Col, Nav, OverlayTrigger, Row, Form, Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
import {Grid, _ } from "gridjs-react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import toast, { Toaster } from 'react-hot-toast'

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";



export default function User() {

  const axiosPrivate = useAxiosPrivate();
  const {
      register,
      handleSubmit,
      formState,
      formState: { errors, isSubmitSuccessful},
      reset
  } = useForm({ defaultValues: { name: "", email: "", password: "", telefone: "" } })

  const [users, setUsers] = useState(0);
  const [values, setValues] = useState(89.00);
  const [usersData, setUsersData] = useState([] );
  const currentDate = new Date().toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  const currentSkin = (localStorage.getItem('skin-mode'))? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  useEffect( () => {
    const getUsers = async () => {
      await axiosPrivate.get('/users')
          .then(response => {
            if(response.data) {
              setUsers(response.data.content.length);
              setUsersData(response.data.content);
            }
          })
    }
      getUsers().catch(console.error);
  },[])

  const handleEdit = (id) => {
    console.log("Editar item com ID:", id);
    // Implemente a lógica de edição aqui
  };

  const handleDelete = (id) => {
    console.log("Deletar item com ID:", id);
    // Implemente a lógica de exclusão aqui
  };


  const onSubmit = async (data) => {
    data['role'] = 'User'

    console.log(data);
    try{
      const response = await axiosPrivate.post('/users', data)
      const result = await axiosPrivate.get('/users')
      toast.success('Cadastrado com sucesso')
      reset( )
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.title)

    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <Header onSkin={setSkin} />
      <Card className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Super Admin</li>
            </ol>
            <h4 className="main-title mb-0">Usuários</h4>
          </div>
        </div>
        <Row className="g-3">
          {[
            {
              "label": "Data",
              "icon": "ri-inbox-line",
              "value": `${currentDate}`,
              "percent": "Fechamento todo dia 05",
              "status": "up"
            },
            {
              "label": "Usuários",
              "icon": "ri-shopping-bag-3-line",
              "value": `${users || 0}`,
              "percent": "Total de usuários ativos",
              "status": "up",
              "message": ""
            }
          ].map((card, index) => (
            <Col xs="6" xl="3" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                  <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                  <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent} </span> {card.message}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}

        </Row>

        <Row className="g-3 mt-3">
          <Col xs="12" sm="12" xl>
            <Card className="card-one">
            <Card.Body>
              <div className="text-dark card-title h5">Cadastrar Administrador das Franquias</div>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control type="text" placeholder="Nome" name="name" { ...register("name", {
                    required: "Campo obrigatório",
                    minLength: {
                      value: 4,
                      message: "Nome deve ter no mínimo 3 caracteres"
                    }
                  })}/>
                  {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control type="text" placeholder="phone" name="phone" { ...register("phone", {
                    required: "Campo obrigatório",
                    minLength: {
                      value: 11,
                      message: "Nome deve ter no mínimo 11 caracteres"
                    }
                  })}/>
                  {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control type="email" placeholder="E-mail" name="email" { ...register("email", {
                    required: "Campo obrigatório",
                    pattern: {
                      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "E-mail inválido"
                    }
                  })}/>
                  {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control type="password" placeholder="Senha" name="password" { ...register("password", {
                    required: "Campo obrigatório",
                    minLength: {
                      value: 8,
                      message: "A Senha deve ter no mínimo 8 caracteres"
                    }
                  })}/>
                  {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
                </Form.Group>
                <Button variant="primary" type="submit" onClick={() => handleSubmit(onSubmit)()}>
                  Cadastrar
                </Button>



              </Col>
            </Card.Body>
          </Card>
          </Col>
        </Row>

        <Row className="g-3 mt-3">
          <Col xs="12" sm="12" xl>
            <Card className="card-one">
            <Card.Body>
              <Grid
                  data={usersData.map(item => [item.name, item.email, item.role, item.isActive ? 'SIM' : 'NÃO',
                    _(<button className={"btn btn-primary"} onClick={() => alert(item.id)}>Editar</button>)])}
                  columns={['Nome', 'Email', 'Cargo', 'Ativo', 'Opções']}
                  search={true}
                  pagination={true}
                  sort={true}
                  resizable={true}
                  className={{
                    table: 'table table-bordered mb-0'
                  }}
              />
            </Card.Body>
          </Card>
          </Col>
        </Row>
        <Footer />
      </Card>
    </React.Fragment>
  )
}