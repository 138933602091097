import React, {useState, useEffect} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast'
import {TagsInput} from "react-tag-input-component"
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import './ImageFast.css';
import {formatNumberArray} from "../utils/formatNumber";
import {sendImages} from "../api/axiosZap";
import messageData from '../data/Messages.json';
import {Button, Image, Space, Upload} from 'antd';
import {useLiveQuery} from "dexie-react-hooks";
import db from '../data/db';
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import {atualizaCsv, formattedDate} from "../utils/atualizaCsv";

export default function ImageFast() {
    const [phone, setPhone] = useState(JSON.parse(localStorage.getItem('sendList')) || []);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('Nenhum arquivo Selecionado!');
    const [imageSrc, setImageSrc] = useState(null);
    const [caption, setCaption] = useState('');
    const [message, setMessage] = useState();
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const msgToast = messageData.toast;
    const [fileList, setFileList] = useState([{}]);
    const [msgSend, setMsgSend] = useState(null);

    const imagens = useLiveQuery(async () => {
        //
        const result = await db.imagens.toArray();
        if(result.length > 0) {
            setImageSrc(URL.createObjectURL(result[0].file));
            setFileName(result[0].file.name);
            setFile(result[0].file)
            setCaption(result[0].caption);
            setMessage(result[0].message);

        }
        return result;
    });

    useEffect(() => {
        if(phone) {
            setMsgSend(null)
        }
    },[phone])



    const handleClearForm = async (e) => {
        e.preventDefault();
        const confirmacao = window.confirm("Tem certeza que deseja deletar o modelo de mensagem?");
        if(confirmacao){
            setMessage('');
            setCaption('');
            setFile(null);
            setImageSrc(null);
            setFileName('Selecione um arquivo!');
            let btnRemove = document.querySelector('button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only[title="Remove file"]');
            if (btnRemove) {
                btnRemove.click();
            } else {
                console.log("Botão não encontrado");
            }
            if(imagens.length > 0) {
                await handleDelete(imagens[0].id);
            }
            await db.imagens.clear()
        }

    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonSubmit(true)
        const tloading = toast.loading(msgToast.send_message.sending);


        if(!file) {
            toast.error(msgToast.input.error_not_image)
            await toast.remove(tloading);
            setButtonSubmit(false);
            return;
        }


        const phones = await formatNumberArray(phone)
        if(phones.length === 0 ) {
            toast.error(msgToast.input.error_not_phone)
            await toast.remove(tloading);
            setButtonSubmit(false);
            return;
        }

        const phonesSemEspacaoBranco = phones.map(num => num.trim());
        const phonesEmString = phonesSemEspacaoBranco.join(',');

        const formData = new FormData();
        formData.append('phones', phonesEmString)
        formData.append("image", file);

        if(message){
            formData.append("message", message);
        }

        if (caption) {
            formData.append('caption', caption)
        }

        try{
            const response = await sendImages(formData)
            if(response.data.length === phones.length && response.status === 200) {
                atualizaCsv(response.data);

                await toast.remove(tloading);
                setButtonSubmit(false);
                localStorage.removeItem('sendList');
                toast.success(`Mensagem(s) enviada(s)`);
                setMsgSend(`Envio finalizado: ${formattedDate()}`)
            }
        } catch (error) {
            await toast.remove(tloading);
            toast.error('Erro ao enviar mensagem!');
            setButtonSubmit(false);
        }
    }

    async function deleteItemByKeyPath(key) {
        await db.imagens.delete(key);
    }
    const handleDelete = async (key) => {
        try {
            await deleteItemByKeyPath(key);
            console.log(`Item com chave ${key} foi deletado.`);
            // Atualize o estado do componente ou realize outras ações conforme necessário
        } catch (error) {
            console.error('Erro ao deletar o item:', error);
        }
    };

    const props = {
        onRemove: (file) => {
            setFile(null);
            setFileName(null);
            setImageSrc(null);
        },
        beforeUpload: (file) => {
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                toast.error('Imagem acima do tamanho permitido!');
                return
            }
            setFile(file);
            setFileName(file.name)
            setImageSrc(URL.createObjectURL(file));
            return false;
        },
        file
    };

    const salvarMensagem = async (e) =>{
        e.preventDefault();
        await db.imagens.clear();
        await db.imagens.add({file: file, message: message, caption: caption});
        toast.success('Modelo de mensagem salvo!');
    }

    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Mensagens</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Envio Rápido</li>
                        </ol>
                        <h4 className="main-title mb-0">Envie mensagem com imagem!</h4>
                    </div>
                </div>
                <Row>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicPhones">
                                    <Form.Label>Telefone(s) com DDD, exemplo 51987654xxx </Form.Label>
                                    <TagsInput value={phone} onChange={setPhone} name="phone" className="form-control"
                                               id="phone" placeHolder="Digite o telefone e pressione enter" onExisting/>
                                    <Form.Text>
                                        { msgSend ?
                                            <span style={{color: "green"}}>{msgSend}</span>  : "Pressione ENTER para confirmar cada número inserido e digite a quantidade que desejar."}
                                    </Form.Text>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Button  className="btn-icon" title="Limpar Telefones"
                                                 onClick={(e) => {
                                                     e.preventDefault();
                                                     setPhone([]);
                                                     localStorage.removeItem("sendList");
                                                 }}><i className="ri-eraser-fill"></i></Button>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicImage">
                                    <Form.Label>Adicione uma imagem.</Form.Label>
                                    <Space direction="vertical" style={{ width: '100%' }} size="large">
                                        <Upload
                                            {...props}
                                            accept=".png,.jpg,.webp"
                                            listType="picture"
                                            maxCount={1}

                                            className="upload-list-inline">
                                            <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                                        </Upload>
                                    </Space>
                                </Form.Group>
                                {
                                    imageSrc ?
                                        <Row className="d-flex justify-content-center">
                                            <Image width={200} src={imageSrc} />
                                            <Row className="d-flex justify-content-center ">
                                                <Button style={{margin: "20px 0px", maxWidth: "50%"}}
                                                        icon={<DeleteOutlined />}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setFile(null);
                                                            setFileName(null);
                                                            setImageSrc(null);
                                                            let btnRemove = document.querySelector('button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only[title="Remove file"]');
                                                            if (btnRemove) {
                                                                btnRemove.click();
                                                            } else {
                                                                console.log("Botão não encontrado");
                                                            }
                                                        }}
                                                >Remover Imagem</Button>
                                            </Row>
                                        </Row>
                                        : <div></div>
                                }
                                <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicImage">
                                        <Form.Label>Mensagem junto a imagem</Form.Label>
                                        <Form.Control as="textarea" aria-label="With textarea" rows={15} value={caption}
                                                      width="100%" name="caption"
                                                      onChange={(e) => setCaption(e.target.value)}/>
                                        <Form.Text>
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="formBasicText">
                                    <Form.Label>Mensagem Adicional</Form.Label>
                                    <Form.Control as="textarea" aria-label="With textarea" rows={15} name="message"  value={message}
                                    onChange={(e) => setMessage(e.target.value)}/>
                                    <Form.Text>

                                    </Form.Text>
                                </Form.Group>
                                </Col>
                                </Row>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary me-2" disabled={buttonSubmit}>
                                        ENVIAR MENSAGEM <i className="ri-chat-smile-2-fill"></i></button>

                                    <button className="btn btn-info " onClick={salvarMensagem}>
                                        SALVAR MENSAGEM <i className="ri-save-3-line"></i></button>

                                    <button className="btn btn-secondary" onClick={handleClearForm}>
                                        DELETAR MENSAGEM <i className="ri-eraser-fill"></i></button>
                                </div>
                            </Form>

                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}