import React, {useState, useEffect} from "react";
import { Card, Col, Nav, OverlayTrigger, Row, Form, Tooltip, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import { sendMessages } from "../api/axiosZap";
import toast, {Toaster} from 'react-hot-toast'
import {TagsInput} from "react-tag-input-component"
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { formatNumberArray } from "../utils/formatNumber";
import messageData from '../data/Messages.json';
import { Button, message, Upload } from 'antd';
import {atualizaCsv} from "../utils/atualizaCsv";
import {format} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import db from "../data/db";

const MSG_WHAT = "🌟 *Super Oferta de Beleza!* 🌟\n" +
    "\n" +
    "Olá! 👋 Estamos super empolgados em apresentar a você nossa *Máscara Facial Revitalizante com Colágeno* 🧖‍♀️ - o segredo para uma pele radiante e jovem! \n" +
    "\n" +
    "🔔 *Por tempo limitado*, estamos oferecendo um desconto especial de *30%* em cada compra.\n" +
    "\n" +
    "*Por que você vai amar?* 💖\n" +
    "- 🌱 Ingredientes Naturais\n" +
    "- 🚫 Sem Parabenos\n" +
    "- 💦 Hidratação Intensa\n" +
    "- 🌞 Proteção contra danos solares\n" +
    "\n" +
    "*Preço especial*: Apenas R$59,90! (Preço original: R$85,50)\n" +
    "\n" +
    "🛒 Para comprar, clique aqui: [Link da Loja]\n" +
    "\n" +
    "🎁 *Bônus*: Nas compras acima de R$100, ganhe uma amostra exclusiva do nosso novo _Creme Noturno Rejuvenescedor_!\n" +
    "\n" +
    "Não perca essa chance de realçar a sua beleza natural com a nossa Máscara Facial Revitalizante. \n" +
    "\n" +
    "📲 Para mais informações, responda a esta mensagem ou entre em contato pelo nosso WhatsApp em (XX) XXXXX-XXXX.\n" +
    "\n" +
    "*Promoção válida até 30/04/2024*.\n"

export default function TextFast() {
    const [phone, setPhone] = useState(JSON.parse(localStorage.getItem('sendList')) || []);
    const [message, setMessage] = useState(localStorage.getItem('lastMessage') || '');
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const msgToast = messageData.toast;
    const [msgSend, setMsgSend] = useState(null)

    useEffect(() => {
        if(phone) {
            setMsgSend(null)
        }
    },[phone])

    const formattedDate = () => {
        // Obter a data e hora atual
        const now = new Date();

        // Formatar a data e hora
        // 'dd/MM/yyyy HH:mm' é o padrão para dia/mês/ano horas:minutos
        return format(now, 'dd/MM/yyyy HH:mm', {locale: ptBR});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonSubmit(true)
        const tloading = toast.loading(msgToast.send_message.sending);
        const phones = await formatNumberArray(phone);

        if(phones.length === 0) {
            toast.error(msgToast.input.error_not_phone)
            await toast.remove(tloading)
            setButtonSubmit(false)
            return
        }

        if(!message) {
            toast.error(msgToast.input.error_not_message)
            await toast.remove(tloading)
            setButtonSubmit(false)
            return
        }

        try {
            const response = await sendMessages(phones, message);
            if(response.data.length === phones.length && response.status === 200) {
                atualizaCsv(response.data)
                await toast.remove(tloading);
                setButtonSubmit(false);
                setMsgSend(`Envio finalizado: ${formattedDate()}`)
                toast.success(msgToast.send_message.success);
            }
        } catch (error) {
            await toast.remove(tloading);
            toast.error(msgToast.send_message.error);
            setButtonSubmit(false);
        }
    }

    const handleClearForm = (e) => {
        e.preventDefault();
        setMessage('');
        localStorage.removeItem('lastMessage');
    }

    const salvarMensagem = async (e) =>{
        e.preventDefault();
        localStorage.setItem('lastMessage', message);
        toast.success('Modelo de mensagem salvo!');
    }
    return (
        <React.Fragment>
            <Toaster/>
            <Header/>
            <Card className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Mensagens</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Envio Rápido</li>
                        </ol>
                        <h4 className="main-title mb-0">Envie mensagens de texto!</h4>
                    </div>
                </div>
                <Row>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicPhones">
                                    <Form.Label>Telefone(s) com DDD, exemplo 51987654xxx </Form.Label>
                                    <TagsInput value={phone} onChange={setPhone}  className="form-control"
                                               placeHolder="Digite o telefone e pressione enter"
                                    />
                                    <Form.Text>
                                        { msgSend ?
                                            <span style={{color: "green"}}>{msgSend}</span>  : "Pressione ENTER para confirmar cada número inserido e digite a quantidade que desejar."}
                                    </Form.Text>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Button  className="btn-icon" title="Limpar Telefones"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setPhone([]);
                                            localStorage.removeItem("sendList");
                                        }}><i className="ri-eraser-fill"></i></Button>
                                    </div>

                                </Form.Group>


                                <Form.Group className="mb-3" controlId="formBasicText">
                                    <Form.Label>Digite sua mensagem! Use emojis 🌟 e a formatação aceita pelo Whastsapp!
                                        <Button className={"m-1"} type="primary" size="small" onClick={(e) => {
                                            const isConfirmed = window.confirm("Este procedimento vai sobrescrever o texto que estiver na caixa abaixo! Deseja continuar?");
                                            if (isConfirmed) {
                                                e.preventDefault();
                                                setMessage(MSG_WHAT)
                                            }
                                            }}>VER MODELO!
                                        </Button>
                                    </Form.Label>
                                    <Form.Control as="textarea" aria-label="With textarea" value={message} rows={15} name="message"
                                    onChange={(e) => setMessage(e.target.value)}/>
                                    <Form.Text>

                                    </Form.Text>
                                </Form.Group>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary me-2" disabled={buttonSubmit}>
                                        ENVIAR MENSAGEM <i className="ri-chat-smile-2-fill"></i></button>

                                    <button className="btn btn-info " onClick={salvarMensagem}>
                                        SALVAR MENSAGEM <i className="ri-save-3-line"></i></button>

                                    <button className="btn btn-secondary" onClick={handleClearForm}>
                                        DELETAR MENSAGEM <i className="ri-eraser-fill"></i></button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </Card>
        </React.Fragment>
    )
}